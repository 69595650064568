query getCourses {
	getCourses {
		courseID
		name
		description
		photo
		defaultPrice
		defaultEquipmentFee
		defaultDuration
		defaultCapacityAvailable
		defaultEquipmentAvailable
		defaultLocation {
			locationID
			name
			plusCode
			coordinates {
				latitude
				longitude
			}
		}
		defaultInstructors {
			instructorID
			photo
			details {
				detailsID
				firstName
				lastName
				nickName
				emailAddress
				mobilePhoneNumber
			}
		}
	}
}

mutation updateCourse($courseID: UUID!, $input: CourseInput!) {
	updateCourseByID(courseID: $courseID, input: $input) {
		courseID
		name
		description
		photo
		defaultPrice
		defaultEquipmentFee
		defaultDuration
		defaultCapacityAvailable
		defaultEquipmentAvailable
		defaultLocation {
			locationID
			name
			plusCode
			coordinates {
				latitude
				longitude
			}
		}
		defaultInstructors {
			instructorID
			photo
			details {
				detailsID
				firstName
				lastName
				nickName
				emailAddress
				mobilePhoneNumber
			}
		}
	}
}

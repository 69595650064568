query getStudentDetails($studentID: String!) {
	getStudentByID(studentID: $studentID) {
		studentID
		details {
			detailsID
			mobilePhoneNumber
			emailAddress
			instagramUsername
		}
		bookingsTotal
		bookings {
			bookingID
			notes
			createdAt
			bookingQuantity
			equipmentQuantity
			paymentMethod
			cost
			isCheckedIn
			isCancelled
			session {
				sessionID
				title
				startTime
				endTime
			}
		}
	}
}

query getLocations {
	getLocations {
		locationID
		name
		plusCode
		address
		coordinates {
			latitude
			longitude
		}
	}
}

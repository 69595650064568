query getAccountPage {
	getStudentByID {
		studentID
		createdAt
		details {
			detailsID
			firstName
			lastName
			nickName
			emailAddress
			mobilePhoneNumber
			instagramUsername
		}
	}
}

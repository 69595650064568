mutation updateStudent($detailsInput: DetailsInput!) {
	updateStudentByID(input: $detailsInput) {
		studentID
		createdAt
		details {
			detailsID
			firstName
			lastName
			nickName
			emailAddress
			mobilePhoneNumber
			instagramUsername
		}
	}
}

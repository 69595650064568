mutation createInstructor($input: InstructorInput!) {
	createInstructor(input: $input) {
		instructorID
		photo
		createdAt
		details {
			detailsID
			firstName
			lastName
			nickName
			mobilePhoneNumber
			emailAddress
			instagramUsername
			createdAt
		}
	}
}

query getSessionPage($sessionID: UUID!) {
	getSessionByID(sessionID: $sessionID) {
		sessionID
		title
		notes
		startTime
		endTime
		price
		hasBooked
		equipmentFee
		capacityAvailable
		capacityBooked
		capacityRemaining
		equipmentAvailable
		equipmentHired
		equipmentRemaining
		isCapacityRemaining(bookingQuantity: 1)
		isCancelled
		viewsCount
		createdAt
		location {
			locationID
			name
			plusCode
			address
			coordinates {
				latitude
				longitude
			}
		}
		course {
			courseID
			name
			photo
			description
		}
		instructors {
			instructorID
			photo
			details {
				detailsID
				firstName
				lastName
				nickName
				emailAddress
				mobilePhoneNumber
				instagramUsername
				createdAt
			}
		}
	}
	getClassDescription
}

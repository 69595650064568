query getPaymentScreenData($sessionID: UUID!, $bookingInput: BookingInput!) {
	getSessionByID(sessionID: $sessionID) {
		sessionID
		title
		price
		equipmentFee
		startTime
		endTime
		capacityRemaining
		equipmentRemaining
		isCancelled
		location {
			locationID
			name
		}
		course {
			courseID
			name
			photo
		}
	}
	getBookingCost(bookingInput: $bookingInput) {
		sessionCost
		equipmentCost
		bookingCost
		couponDiscountPercentage
		couponAmountToDiscount
		couponDiscount
		cost
		isFreeFromCoupon
		cardFee
		cardSurchargeRatio
		cardSurcharge
		cardSurchargeWithoutFee
		finalCost
	}
	getCanBookSession(bookingInput: $bookingInput)
	hasSignedWaiver
}

query sessionViews($sessionID: UUID!) {
	getSessionByID(sessionID: $sessionID) {
		sessionID
		views {
			hasBooked
			hasCancelled
			createdAt
			student {
				studentID
				details {
					detailsID
					firstName
					lastName
					nickName
					emailAddress
					mobilePhoneNumber
					instagramUsername
					createdAt
				}
			}
		}
	}
}

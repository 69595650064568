query getSessionsInPeriod($input: GetSessionsInput!) {
	getSessionsInPeriod(input: $input) {
		sessionID
		title
		startTime
		endTime
		price
		hasBooked
		viewsCount
		capacityRemaining
		capacityBooked
		isCancelled
		location {
			locationID
			name
		}
		course {
			courseID
			name
			photo
		}
	}
}

query getStudents {
	getStudents {
		studentID
		bookingsTotal
		hasSignedWaiver
		bookingsTotalPaymentMethodFree
		bookingsTotalPaymentMethodCard
		bookingsTotalPaymentMethodCash
		details {
			detailsID
			firstName
			lastName
			nickName
			emailAddress
			mobilePhoneNumber
			instagramUsername
		}
	}
	getGrossTotal
	getStudentsTotal
	getBookingsTotal
}

query getMerchItems {
	getMerchItems {
		merchItemID
		name
		description
		price
		photo
		isInStock
		isLowStock
		isPreOrder
		sizesAvailable
	}
}

mutation updateInstructor($instructorID: UUID!, $input: InstructorInput!) {
	updateInstructorByID(instructorID: $instructorID, input: $input) {
		instructorID
		photo
		details {
			detailsID
			firstName
			lastName
			nickName
			emailAddress
			mobilePhoneNumber
			instagramUsername
			createdAt
		}
	}
}

mutation updateLocation($locationID: UUID!, $input: LocationInput!) {
	updateLocationByID(locationID: $locationID, input: $input) {
		locationID
		name
		plusCode
		address
		coordinates {
			latitude
			longitude
		}
	}
}

query getMerchItemByID($merchItemID: String!) {
	getMerchItemByID(merchItemID: $merchItemID) {
		merchItemID
		name
		description
		price
		photo
		isInStock
		isLowStock
		isPreOrder
		sizesAvailable
	}
}

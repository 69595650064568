mutation createCourse($input: CourseInput!) {
	createCourse(input: $input) {
		courseID
		name
		description
		photo
		defaultPrice
		defaultEquipmentFee
		defaultDuration
		defaultCapacityAvailable
		defaultEquipmentAvailable
		defaultLocation {
			locationID
			name
		}
		defaultInstructors {
			instructorID
			photo
			details {
				detailsID
				firstName
				lastName
				nickName
				mobilePhoneNumber
				emailAddress
			}
		}
	}
}

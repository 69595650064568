query searchPlaceByName($name: String!) {
	searchPlaceByName(name: $name) {
		address
		plusCode
		coordinates {
			latitude
			longitude
		}
	}
}

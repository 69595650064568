query getCourseFormData {
	getInstructors {
		instructorID
		photo
		details {
			detailsID
			firstName
			lastName
			nickName
		}
	}
	getLocations {
		locationID
		name
	}
}

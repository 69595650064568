query getPaymentSuccessData($bookingID: UUID!) {
	getBookingByID(bookingID: $bookingID) {
		bookingID
		session {
			sessionID
			title
			price
			equipmentFee
			startTime
			endTime
			capacityRemaining
			isCancelled
			location {
				locationID
				name
			}
			course {
				courseID
				name
				photo
			}
		}
	}
}

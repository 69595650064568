query getSessionBookings($sessionID: UUID!) {
	getSessionByID(sessionID: $sessionID) {
		sessionID
		gross
		bookings {
			bookingID
			notes
			createdAt
			bookingQuantity
			equipmentQuantity
			paymentMethod
			paymentIntentID
			cost
			isCheckedIn
			isCancelled
			cancelledAt
			session {
				sessionID
			}
			student {
				studentID
				details {
					detailsID
					firstName
					lastName
					nickName
					emailAddress
					mobilePhoneNumber
					instagramUsername
				}
			}
		}
	}
}

query getInstructors {
	getInstructors {
		instructorID
		photo
		details {
			detailsID
			firstName
			lastName
			nickName
			emailAddress
			mobilePhoneNumber
			instagramUsername
			createdAt
		}
	}
}

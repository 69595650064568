import { DetailsInput } from "../../../generated-types";

export const initialInput: DetailsInput = {
	firstName: "",
	nickName: null,
	lastName: "",
	gender: null,
	mobilePhoneNumber: "",
	emailAddress: "",
	instagramUsername: null,
};
